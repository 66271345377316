<script>
    import {fade} from "svelte/transition";

    export let visible = true;
</script>

{#if visible}
    <div transition:fade>
        <svg width="100%" height="100%" viewBox="0 0 360 640" fill="none" xmlns="http://www.w3.org/2000/svg"
             style="position:absolute">
            <rect width="360" height="640" fill="#262B46"/>
            <circle cx="341.334" cy="413.706" r="126.351" fill="#0E1D44"/>
            <rect x="-79" y="288.38" width="141.241" height="141.241" rx="10" transform="rotate(-45 -79 288.38)"
                  fill="#0E1D44"/>
            <line x1="381.312" y1="111.889" x2="-69.1215" y2="556.68" stroke="#0E1D44" stroke-width="2.5"/>
            <line x1="392.073" y1="122.787" x2="-58.3604" y2="567.577" stroke="#0E1D44" stroke-width="2.5"/>
            <line x1="402.834" y1="133.685" x2="-47.5994" y2="578.475" stroke="#0E1D44" stroke-width="2.5"/>
            <line x1="413.595" y1="144.582" x2="-36.8383" y2="589.373" stroke="#0E1D44" stroke-width="2.5"/>
            <line x1="424.356" y1="155.48" x2="-26.0772" y2="600.27" stroke="#0E1D44" stroke-width="2.5"/>
            <line x1="435.117" y1="166.377" x2="-15.3162" y2="611.168" stroke="#0E1D44" stroke-width="2.5"/>
            <line x1="445.879" y1="177.275" x2="-4.55507" y2="622.065" stroke="#0E1D44" stroke-width="2.5"/>
        </svg>
    </div>
{/if}

<style>
    div {
        position: absolute;
        z-index: -9999;
        width: 100vw;
        aspect-ratio: 360/640;
        top: 0;
    }
</style>